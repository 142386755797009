.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 24px;
}

.btn {
    margin-right: 10px;
}

.spinner-container {
    width: 24px; 
    height: 24px;
    display: flex;
}

#upload-json-landing-screen-upload-container {
    margin-top: 0.5rem;
    display: flex;
    width: 50%;
    left: 25%;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
    position: relative;
    display: inline-block;
    border-style: solid;
    border-color: black;
}

#upload-json-landing-screen-upload-icon {
    margin-top: 1.5rem;
    width: 15%;
    justify-content: center;
}

#upload-json-landing-screen-button {
    display: inline-block;
    cursor: pointer;
    width: 8rem;
    text-align: center;
    transition: 0.4s;
    border-style: solid;
    border-color: #2F7ABC;
    background-color: #2F7ABC;
    padding: 0.75em;
    border-radius: 0;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
}

#upload-json-landing-screen-button:hover,
#upload-test-landing-screen-button:focus {
    border-width: 2px;
    border-style: solid;
    border-color: #1D4C72;
    background-color: #1D4C72;
    color: white;
    text-decoration: underline;
}

#upload-json-landing-screen-upload-text {
    margin: 2rem;
}

#upload-json-landing-screen-upload-text-bold {
    margin: 2rem;
    font-weight: bold;
}

#remove-json-landing-screen-button {
    display: inline-block;
    cursor: pointer;
    width: 8rem;
    text-align: center;
    transition: 0.4s;
    border-style: solid;
    border-color: #FE414D;
    background-color: #FE414D;
    padding: 0.75em;
    border-radius: 0;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
}

#remove-json-landing-screen-button:hover,
#remove-json-landing-screen-button:focus {
    border-width: 2px;
    border-style: solid;
    border-color: #AB2730;
    background-color: #AB2730;
    color: white;
    text-decoration: underline;
}

.biform {
    margin: auto;
    max-width: 35rem;
    text-align: left;
}

.biform.center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.biform h1 {
    text-align: center;
}

.biform label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.biform select {
    width: 100%;
    padding: 0.7rem 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.7rem;
    transition: 0.4s;
    background-color: white;
    border: gray solid 1px;
    border-radius: 3px;
    box-sizing: border-box;
    color: black;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='black' d='M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z'/></svg>");
}

.biform form {
    margin-left: 0px;
    width: 100%;
}

.biinstruc {
    text-align: left;
    margin: 2rem 0rem;
}

.additional-info {
    font-size: 0.85em;
}

.additional-info.bottom-margin {
    margin: 0 0 1em 0;
}

.additional-info.bottom-center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dropzone:hover {
    cursor: pointer;
}

@media (max-width : 1000px) {
    .biform {
        width: 100%;
    }
}