@media (min-width : 1330px) {
  #navbarcollapse { display: none; }
  #navbar-right { margin-right: 1.2rem; }
}

#navlogo {
  float: left;
  margin-top: -1.5rem;
  background-color: #FF5C6C;
  padding: 1.2rem 1.5rem;
  outline-offset: -7px;
}

#navbarcollapse {
  float: right;
  padding-right: 3rem;
}

#drawerbtnup {
  color: #fff;
  float: right;
  background-color: #000;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: 0.4s;
  border: none;
  border-radius: 0;
}

#drawerbtnup:hover {
  color: #fff;
  background-color: #BE3E4A;
  transition: 0.4s;
  text-decoration: none;
}

#navbar {
  overflow: hidden;
  background-color: black;
  height: 2rem;
  padding: 1.5rem;
  padding-right: 10rem;
  transition: 0.4s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
}

#sitetitle {
  color: #fff;
  font-size: 1.7rem;
  text-decoration: none;
  transition: 0.4s;
  font-weight: bold;
  margin-left: 1.5rem;
  float: left;
} 

#sitetitle:hover {
  color: #FF5C6C;
  transition: 0.4s;
}

#navbar-right {
  float: right;
  margin-top: 0.3rem;
  display: block;
}

#navbar-right a {
  color: #fff;
  background-color: #000;
  text-decoration: none;
  border-bottom: #000 solid 4px;
  padding: 2rem 1.5rem;
  margin-left: 1rem;
  transition: 0.5s;
}

#navbar-right a:first-child {
  margin-left: 0;
}

#navbar-right a:hover {
  background-color: #BE3E4A;
  border-bottom: #BE3E4A solid 4px;
  transition: 0.5s;
  cursor: pointer;
}

#navbar-right #logout {
  transition: 0.5s;
  display: none;
}

#navbar-right #logout:hover {
  transition: 0.5s;
}

#admin-login-modal-container {
  margin-left: 2rem;
  margin-top: 1.6rem;
  height: 24rem;
  width: 25rem;
}

/* The Modal (background) */
.login-modal {
  color: white;
  display: none; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  width: 10rem; /* Full width */
  height: 20rem; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(33, 33, 33, 0.8); /* Black w/ opacity */  
}

/* Modal Content/Box */
.modal-content {
  display: flex;
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;/* Could be more or less, depending on screen size */
}

.modal-form {
  margin-top:8rem;
}

/* The Close Button */
.close {
  margin-top: -8rem;
  margin-right: 0.5rem;
  color: rgb(255, 255, 255);
  float: right;
  font-size: 2.5rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#admin-password-input {
  width: auto;
}

#modal-signin-button {
  font-weight: bold;
  color: white;
  border: 0;
  background-color: black;
  height: 2rem;
  width: 11rem;
  border-radius: 1rem;
}

#modal-signin-button:hover{
  background-color: #FE414D;
}

#modal-password-label {
  text-align: left;
}

@media print {
  #navbar {
    display: none;
  }
}

@media screen and (max-width: 1330px) {
  #sitetitle {
    display: none;
  }
  #navbar {
    height: 0.3%;
  }
  #navbar-right {
    display: none;
    margin-top: -0.1rem;
    background-color: #000;
    transition: 0.4s;
  }
  #navbar-right a {
    display: block; 
    width: 100vw;
    text-align: center;
    padding: 1.2rem;
    box-sizing: border-box;
  }
  #drawerbtnup {
    margin-top: -26px;
  }
  #navlogo {
    padding: 0.2rem 1.5rem;
    transition: 0.4s;
  }
  #navbar-right a:first-child {
    margin-left: 1rem;
  }
}

#skipLink {
  height: 0;
  width: 0;
  overflow: hidden;

  top: 0.3rem;
  left: 0.3rem;
  z-index: 999;
  background-color: white;
  color: black;
  position: absolute;
  white-space: nowrap;
}

#skipLink:focus {
  overflow: visible;
  height: auto;
  width: auto ;
  padding: 0.2rem;
  outline-offset: -1px;
}

#navbar-right a:focus, #navlogo a:focus, #drawerbtnup:focus {
  outline-offset: -10px;
} 

.logout{
  margin-right: 20px;
}

#navbar a:focus, #navbarcollapse a:focus, #drawerbtnup:focus {
  outline-color: #489AFE;
}